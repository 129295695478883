import React, { useContext, useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { Context } from "../store/appContext";
import "../../styles/companies-details.css";
import { Uploader } from "../component/uploader/uploader.jsx";
import profile from "../../images/profile.png";
import Swal from 'sweetalert2'
import WithAuth from "../component/Auth/withAuth";

export const AdminLeadDetails = () => {
    const { store, actions } = useContext(Context);
    const params = useParams();
    let [leadInfo, setLeadInfo] = useState({})

    const getLeadInfo = async () => {
        let { responseJson, response } = await actions.useFetch(`/leads/${params.leadid}`)
        if (response.ok) {
            setLeadInfo(responseJson)
        }
    }

    useEffect(() => {
        getLeadInfo()
    }, [params.leadid]);

    return (
        <div className="container-fluid main-content px-2 px-lg-4 h-90" style={{ marginTop: '150px' }}>
            <div className="row g-3 g-lg-4 mb-4">
                <div className="col-lg-12">
                    <div className="company-details px-3 px-lg-4 py-4 rounded">
                        <h4 className="mb-3" style={{ color: '#5075d0' }}>Contact Details</h4>
                        <div className="row d-flex">
                            <div className="col-xl-6">
                                <p>Name: {leadInfo.first_name} {leadInfo.last_name}</p>
                                <p>Email: {leadInfo.email}</p>
                                <p>Phone: {leadInfo.phone}</p>
                                <p>Date: {leadInfo.created_at}</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div >
    );

};

export default WithAuth(AdminLeadDetails);
