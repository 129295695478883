import React, { useState, useEffect, useContext } from "react";
import { Context } from "./store/appContext";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import ScrollToTop from "./component/scrollToTop";

import { Home } from "./pages/home";
import { Demo } from "./pages/demo";
import { Single } from "./pages/single";
import injectContext from "./store/appContext";

import { Navbar } from "./component/navbar";
import { Sidebar } from "./component/sidebar";
import { Footer } from "./component/footer";
import { AdminSidebar } from "./component/adminSidebar";
import { Login } from "./pages/login.jsx";
import { Signup } from "./pages/signup.jsx";
import { SignupBusiness } from "./pages/signupBusiness.jsx";
import { SignupOwners } from "./pages/signupOwners.jsx";
import { SignupRegAgent } from "./pages/signupRegAgent.jsx";
import { SignupEin } from "./pages/signupEin.jsx";
import Backoffice from "./pages/backoffice.jsx";
import Dashboard from "./pages/dashboard.jsx";
import MailDocuments from "./pages/mailDocuments.jsx";
import BuyMore from "./pages/buyMore.jsx";
import CancelPayment from "./pages/cancelPayment.jsx";
import SuccessPayment from "./pages/successPayment.jsx";
import CancelPurchase from "./pages/cancelPurchase.jsx";
import SuccessPurchase from "./pages/successPurchase.jsx";
import SignupSelectPlan from "./pages/signupSelectPlan.jsx";
import MyCompany from "./pages/myCompany.jsx";
import EditProfile from "./pages/editProfile.jsx";
import BusinessDomain from "./pages/businessDomain.jsx";
import UserServices from "./pages/userServices.jsx";
import UserServices2 from "./pages/userServices2.jsx";
import UserPurchases from "./pages/userPurchases.jsx";
import UserLegal from "./pages/userLegalDoc.jsx";
import UserTax from "./pages/userTax.jsx";
import UserCustomer from "./pages/userCustomer.jsx";
import UserTrademark from "./pages/userTrademark.jsx";
import UserNetwork from "./pages/userNetwork.jsx";
import UserEcommerce from "./pages/userEcommerce.jsx";
import UserBusiness from "./pages/userBusiness.jsx";
import UserHR from "./pages/userHR.jsx";
import UserInvoices from "./pages/userInvoices.jsx";
import NewCompanyBusiness from "./pages/newCompanyBusiness.jsx";
import NewCompanyOwners from "./pages/newCompanyOwners.jsx";
import NewCompanyRegAgent from "./pages/newCompanyRegAgent.jsx";
import NewCompanyEin from "./pages/newCompanyEin.jsx";
import NewCompanySelectPlan from "./pages/newCompanySelectPlan.jsx";

import AdminOrders from "./pages/adminOrders.jsx";
import AdminCompanyDetails from "./pages/adminCompanyDetails.jsx";
import AdminUnpaidUsers from "./pages/adminUnpaidUsers.jsx";
import AdminUserCompanies from "./pages/adminUserCompanies.jsx";
import AdminLeads from "./pages/adminLeads.jsx";
import AdminLeadDetails from "./pages/adminLeadDetails.jsx";
import AdminServices from "./pages/adminServices.jsx";
import AdminServices2 from "./pages/adminServices2.jsx";
import AdminServicesDetails from "./pages/adminServicesDetails.jsx";
import AdminNewService from "./pages/adminNewService.jsx";
import AdminPurchases from "./pages/adminPurchases.jsx";
import adminUnpaidUserDetails, { AdminUnpaidUserDetails } from "./pages/adminUnpaidUserDetails.jsx";
import AdminCustomers from "./pages/adminCustomers.jsx";
import AdminCustomerDetails from "./pages/adminCustomerDetails.jsx";

export const Layout = () => {
  const { store, actions } = useContext(Context);
  const [navbarClicked, setNavbarClicked] = useState(false);
  const [userData, setUserData] = useState(store.userData);

  const handleNavbarClick = () => {
    setNavbarClicked((prevNavbarClicked) => !prevNavbarClicked);
  };

  useEffect(() => {
    setUserData(store.userData);
  }, [store.userData]);

  return (
    <div>
      <ScrollToTop>
        <div
          className={`d-flex wrapper ${navbarClicked ? "toggled" : ""}`}
          id="wrapper"
        >
          {userData != null && userData.role == "admin" ? (
            <AdminSidebar />
          ) : (
            <Sidebar />
          )}
          <div id="page-content-wrapper" className="page-content-wrapper">
            <Navbar handleNavbarClick={handleNavbarClick} />
            <Routes>
              <Route element={<Home />} path="/" />
              <Route element={<Backoffice />} path="/user/backoffice" />
              <Route element={<Dashboard />} path="/user/dashboard" />
              <Route element={<MailDocuments />} path="/user/maildocuments" />
              <Route element={<BuyMore />} path="/user/buymore" />
              <Route element={<CancelPayment />} path="/user/cancelpayment" />
              <Route element={<SuccessPayment />} path="/user/successpayment" />
              <Route element={<CancelPurchase />} path="/user/cancelpurchase" />
              <Route element={<SuccessPurchase />} path="/user/successpurchase" />
              <Route element={<MyCompany />} path="/user/mycompany" />
              <Route element={<BusinessDomain />} path="/user/businessdomain" />
              <Route element={<EditProfile />} path="/user/edit-profile" />
              <Route element={<UserServices2 />} path="/user/services" />
              <Route element={<UserLegal />} path="/user/legal-doc-templates" />
              <Route element={<UserPurchases />} path="/user/purchases" />
              <Route
                element={<UserTax />}
                path="/user/accounting-tax-services"
              />
              <Route element={<UserCustomer />} path="/user/customer-support" />
              <Route
                element={<UserTrademark />}
                path="/user/trademark-intellectual-property"
              />
              <Route
                element={<UserNetwork />}
                path="/user/networking-community-access"
              />
              <Route
                element={<UserEcommerce />}
                path="/user/ecommerce-web-integration"
              />
              <Route
                element={<UserBusiness />}
                path="/user/business-licensing-permits"
              />
              <Route element={<UserHR />} path="/user/hr-payroll-management" />
              <Route element={<UserInvoices />} path="/user/invoices" />
              <Route
                element={<AdminOrders />}
                path="/admin/orders"
              />
              <Route
                element={<AdminUnpaidUsers />}
                path="/admin/unpaid-users"
              />
              <Route element={<AdminUnpaidUserDetails />} path="/admin/unpaid-users/:companyid" />
              <Route element={<AdminUserCompanies />} path="/admin/companies" />
              <Route
                element={<AdminCompanyDetails />}
                path="/admin/companies/:companyid"
              />
              <Route element={<AdminLeads />} path="/admin/leads" />
              <Route element={<AdminLeadDetails />} path="/admin/leads/:leadid" />
              <Route element={<AdminServices2 />} path="/admin/services" />
              <Route element={<AdminNewService />} path="/admin/newservice" />
              <Route
                element={<AdminServicesDetails />}
                path="/admin/services/:serviceid"
              />
              
              <Route element={<AdminPurchases />} path="/admin/purchases" />
              <Route element={<AdminCustomers />} path="/admin/customers" />
              <Route element={<AdminCustomerDetails />} path="/admin/customers/:customerid" />
              <Route element={<h1>Not found!</h1>} />
            </Routes>
            <Footer />
          </div>
        </div>
      </ScrollToTop>
    </div>
  );
};

export const LayoutSignup = () => {
  return (
    <div>
      <ScrollToTop>
        <Routes>
          <Route element={<Layout />} path="/*" />
          <Route element={<Login />} path="/login" />
          <Route element={<Signup />} path="/signup" />
          <Route element={<SignupBusiness />} path="/signup-business" />
          <Route element={<SignupOwners />} path="/signup-owners" />
          <Route element={<SignupRegAgent />} path="/signup-agent" />
          <Route element={<SignupEin />} path="/signup-ein" />
          <Route element={<SignupSelectPlan />} path="/signup-plan" />
          <Route element={<NewCompanyBusiness />} path="/newcompany-business" />
          <Route element={<NewCompanyOwners />} path="/newcompany-owners" />
          <Route element={<NewCompanyRegAgent />} path="/newcompany-agent" />
          <Route element={<NewCompanyEin />} path="/newcompany-ein" />
          <Route element={<NewCompanySelectPlan />} path="/newcompany-plan" />
        </Routes>
      </ScrollToTop>
    </div>
  );
};
