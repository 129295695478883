import React, { useContext, useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { Context } from "../store/appContext";
import "../../styles/home.css";
import WithAuth from "../component/Auth/withAuth";

export const UserPurchases = () => {
    const { store, actions } = useContext(Context);
    const [selectedCompany, setSelectedCompany] = useState("");
    const [myCompanies, setMyCompanies] = useState(store.userData.companies)
    const [companyPurchases, setCompanyPurchases] = useState(null);

    const getCompanyPurchases = async (companyId) => {
        if (!companyId) return;

        let { responseJson, response } = await actions.useFetch(`/purchases/${companyId}`);
        if (response.ok) {
            // Find the selected company from myCompanies
            const selectedCompanyData = myCompanies.find(company => company.id.toString() === companyId);

            if (selectedCompanyData) {
                const transformedCompany = {
                    service: selectedCompanyData.name,
                    description: `${selectedCompanyData.formation_state} - ${selectedCompanyData.type}`,
                    is_paid: selectedCompanyData.is_paid,
                    purchase_date: selectedCompanyData.registration_date,
                    end_date: "" // No end date for company registration
                };

                // Ensure the selected company appears first in the list
                setCompanyPurchases([transformedCompany, ...responseJson]);
            } else {
                setCompanyPurchases(responseJson);
            }
        } else {
            console.log("Failed getting company purchases");
        }
    };

    useEffect(() => {
        setMyCompanies(store.userData.companies)
    }, [store.userData.companies]);

    useEffect(() => {
        if (selectedCompany) {
            getCompanyPurchases(selectedCompany);
        }
    }, [selectedCompany]);

    return (
        <div className="container-fluid main-content px-2 px-lg-4 pt-3 pt-lg-5 mt-5">
            <div className="row">
                <h3 className="mt-4">Purchases</h3>
                <span className="medium">List of all your purchased services</span>
            </div>
            <div className="col-lg-4 mt-3">
                <select id="document-type-select" className="form-select" aria-label="Default select example" onChange={(e) => setSelectedCompany(e.target.value)}>
                    <option value="">Select your company to view its purchases...</option>
                    {myCompanies && myCompanies.length > 0 && (
                        myCompanies.map((item, index) => (
                            <option key={index} value={item.id}>{item.name}</option>
                        ))
                    )}
                </select>
            </div>
            <div className="row my-2 g-3 g-lg-4 contact-section">
                <div className="col">
                    <div className="product-table p-3 px-md-4">
                        <div className="table-responsive">
                            <table className="table align-middle mb-0 bg-white">
                                <thead className="bg-light">
                                    <tr>
                                        <th>#</th>
                                        <th>Service</th>
                                        <th>Description</th>
                                        <th>Status</th>
                                        <th>Purchase Date</th>
                                        <th>End Date</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {companyPurchases && companyPurchases.length > 0 ? (
                                        companyPurchases.map((item, index) => (
                                            <tr key={index}>
                                                <td>{index + 1}</td>
                                                <td>{item.service}</td>
                                                <td>{item.description}</td>
                                                <td>
                                                    <span className={`badge ${item.is_paid == true ? 'bg-success' : 'bg-warning'} rounded-pill`}>
                                                        {item.is_paid == true ? 'paid' : 'pending'}
                                                    </span>
                                                </td>
                                                <td>{item.purchase_date}</td>
                                                <td>{item.end_date}</td>
                                            </tr>
                                        ))
                                    ) : (
                                        <tr>
                                            <td colSpan="5" className="text-center">No Purchased Services</td>
                                        </tr>
                                    )}
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default WithAuth(UserPurchases);
