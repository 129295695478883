import React, { useContext, useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { Context } from "../store/appContext";
import "../../styles/companies-details.css";
import { Uploader } from "../component/uploader/uploader.jsx";
import profile from "../../images/profile.png";
import Swal from 'sweetalert2'
import WithAuth from "../component/Auth/withAuth";

export const AdminServicesDetails = () => {
    const { store, actions } = useContext(Context);
    const params = useParams();
    const [readOnlyPersonal, setReadOnlyPersonal] = useState(true)
    let [serviceInfo, setServiceInfo] = useState({
        service: '',
        price: '',
        description: ''
    });

    const handleInputChange = e => {
        const { name, value } = e.target;
        setServiceInfo(prevState => ({ ...prevState, [name]: value }));
    };

    const updatePersonal = async () => {

        let { responseJson, response } = await actions.useFetch(`/services/${serviceInfo.id}`, serviceInfo, "PUT")
        if (response.ok) {
            getServices()
            setReadOnlyPersonal(!readOnlyPersonal)
            Swal.fire({
                title: 'Great!',
                text: 'You have successfully updated the information',
                icon: 'success',
                confirmButtonColor: '#4874d7'
            })
        } else {
            Swal.fire({
                title: 'Sorry!',
                text: 'There was an error, please try again',
                icon: 'error',
                confirmButtonColor: '#4874d7'
            })
        }
    }

    const getServices = async () => {
        let { responseJson, response } = await actions.useFetch(`/services/${params.serviceid}`)
        if (response.ok) {
            setServiceInfo(responseJson)
        }
    };

    useEffect(() => {
        getServices();
    }, []);


    return (
        <>
            <div className="container-fluid main-content px-2 px-lg-4 pt-3 pt-lg-5 mt-5">

                <div className="row">
                    <h3 className="mt-4">Edit Service</h3>
                    <span className="medium">Here you can edit the service information.</span>
                </div>
                <div className="row g-3 g-lg-4 profile-details-content-3 pb-4" style={{ marginTop: "0px" }}>
                    <div className="col-xl">
                        <div className="p-2 p-sm-4 right-side rounded">
                            <h4 className="mb-3">Service Details</h4>
                            <div className="d-flex row">
                                <div className="col-lg">
                                    <label htmlFor="service" className="large mb-2">Service Name</label>
                                    <input
                                        type="text"
                                        name="service"
                                        id="service"
                                        placeholder="Service Name"
                                        className="form-control mb-3 py-2"
                                        value={serviceInfo.service}
                                        onChange={handleInputChange}
                                        readOnly={readOnlyPersonal}
                                    />
                                </div>
                                <div className="col-lg-6">
                                    <label htmlFor="last-name" className="large mb-2">Price</label>
                                    <input
                                        type="text"
                                        name="price"
                                        id="price"
                                        placeholder="Price"
                                        className="form-control mb-3 py-2"
                                        value={serviceInfo.price}
                                        onChange={handleInputChange}
                                        readOnly={readOnlyPersonal}
                                    />
                                </div>
                            </div>
                            <div className="d-flex row">
                                <div className="col-lg">
                                    <label htmlFor="email" className="large mb-2">Description</label>
                                    <input
                                        type="text"
                                        name="description"
                                        id="description"
                                        placeholder="Description"
                                        className="form-control mb-3 py-2"
                                        value={serviceInfo.description}
                                        onChange={handleInputChange}
                                        readOnly={readOnlyPersonal}
                                    />
                                </div>
                            </div>
                            <div className="d-flex justify-content-center">
                                {readOnlyPersonal ?
                                    <button className="btn btn-primary" onClick={() => setReadOnlyPersonal(!readOnlyPersonal)}>
                                        Edit
                                    </button>
                                    :
                                    <>
                                        <button className="btn btn-primary m-1" onClick={updatePersonal}>
                                            Save
                                        </button>
                                        <button className="btn btn-danger m-1" onClick={() => setReadOnlyPersonal(!readOnlyPersonal)}>
                                            Cancel
                                        </button>
                                    </>}

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );

};

export default WithAuth(AdminServicesDetails);
