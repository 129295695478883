import React from "react";
import '../../styles/services-details.css';
// import Tax from "../../img/tax.jpg";
import WithAuth from "../component/Auth/withAuth";

function userTax() {
    return (

        <div className="service-page-container">
            <div className="service-header">
                <h1>Accounting & Tax Services</h1>
            </div>
            <div className="service-content">
                <img src={Tax} alt="Legal Documents" className="service-image" />
                <div className="service-description">
                    <p>
                        At Afthonia, we understand that robust financial management is the cornerstone of any successful Limited Liability Company (LLC). With this in mind, we offer specialized Accounting & Tax Services designed to streamline your financial operations and ensure tax compliance with ease and accuracy.</p>

                    <p>
                        <strong>Our Accounting & Tax Services feature:</strong>
                        <br />
                        <br /><strong>1.-Comprehensive Bookkeeping:</strong> Stay on top of your financials with our meticulous bookkeeping services, ensuring every transaction is accurately recorded.

                        <br /><strong>2.-Tax Planning and Filing:</strong> Our tax experts are adept at navigating complex tax laws to help you plan and file your taxes, maximizing deductions and minimizing liabilities.

                        <br /><strong>3.-Financial Reporting:</strong> Gain valuable insights into your LLC's financial health with detailed reports, including profit and loss statements, balance sheets, and cash flow analyses.

                        <br /><strong>4.-Payroll Processing:</strong> Simplify your payroll with our efficient processing services, ensuring your employees are paid accurately and on time, while also managing payroll taxes and filings.

                        <br /><strong>5.-Budgeting and Forecasting:</strong> Let us help you create a financial roadmap for your business, setting realistic budgets and forecasts that align with your business goals.
                        <br /><strong>6.-Audit Support:</strong> Should the need arise, our team will be by your side to provide all necessary documentation and support during audits.
                        <br /><strong>7.-Customized to Your LLC's Needs:</strong> Whether you're a newly formed LLC or looking to optimize the financial processes of your existing company, our services are tailored to meet your unique business requirements.

                        With Afthonia's Accounting & Tax Services, you can focus on growing your business, confident in the knowledge that your financial affairs are being expertly managed. Let us handle the numbers, so you can concentrate on what you do best – running your business.
                    </p>
                </div>
            </div>
            <div className="call-to-action">
                <button className="btn btn-transparent" style={{ background: '#8da9ee', color: 'white' }}>Get Started</button>
            </div>
        </div>
    );
};

export default WithAuth(userTax);