import React, { useContext, useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { Context } from "../store/appContext";
import "../../styles/home.css";
import WithAuth from "../component/Auth/withAuth";

export const AdminCustomers = () => {
    const { store, actions } = useContext(Context);
    const [customers, setCustomers] = useState(null);
    const [showSearch, setShowSearch] = useState(false);
    const [searchTerm, setSearchTerm] = useState("");
    const [sortField, setSortField] = useState(null);
    const [sortDirection, setSortDirection] = useState('asc');

    const getCustomers = async () => {
        let { responseJson, response } = await actions.getCustomers();
        if (response.ok) {
            setCustomers(responseJson);
        }
    };

    const handleSearchChange = (event) => {
        let newSearchTerm = event.target.value;
        setSearchTerm(event.target.value);

        if (newSearchTerm.trim() === '') {
            getCustomers()
        }
    };

    const handleSearchIconClick = () => {
        setShowSearch(!showSearch);
        if (!showSearch) {
            setTimeout(() => {
                document.querySelector('.search-input').focus();
            }, 500);
        }
    };

    const handleSearchSubmit = async () => {
        if (searchTerm.trim()) {
            await actions.searchCustomers(searchTerm);
            setCustomers(store.customersSearchResults);
        }
    };


    const handleKeyDown = (event) => {
        if (event.key === 'Enter') {
            handleSearchSubmit();
        }
    };

    const handleSort = (field) => {

        if (sortField === field) {
            setSortDirection(sortDirection === 'asc' ? 'desc' : 'asc');
        } else {
            setSortField(field);
            setSortDirection('asc');
        }

        sortData(field);
    };

    const sortData = (field) => {
        const sortedTasks = [...customers];
        sortedTasks.sort((a, b) => {
            let valA, valB;

            switch (field) {
                case 'first_name':
                    valA = a[field];
                    valB = b[field];
                    return valA.localeCompare(valB) * (sortDirection === 'asc' ? 1 : -1);
                case 'email':
                    valA = a[field];
                    valB = b[field];
                    return valA.localeCompare(valB) * (sortDirection === 'asc' ? 1 : -1);
                case 'created_at':
                    valA = new Date(a.created_at);
                    valB = new Date(b.created_at);
                    break;
                default:
                    return 0;
            }

            // For numerical and predefined order sorting
            return (valA < valB ? -1 : 1) * (sortDirection === 'asc' ? 1 : -1);
        });

        setCustomers(sortedTasks);
    };

    useEffect(() => {
        getCustomers()
    }, []);

    return (
        <>
            <div className="container-fluid main-content px-2 px-lg-4 pt-3 pt-lg-5 mt-5">
                <div className="row justify-content-between align-items-center mt-4">
                    <div className="col-md-6">
                        <h3>Customers</h3>
                        <span className="medium">This is the list of all customers.</span>
                    </div>
                    <div className="col-md-6 d-flex justify-content-end">
                        <input
                            className={`form-control search-input ${showSearch ? 'open' : ''}`}
                            type="search"
                            placeholder="Search"
                            aria-label="Search"
                            value={searchTerm}
                            onChange={handleSearchChange}
                            onKeyDown={handleKeyDown}
                        />
                        <button className="btn btn-transparent" type="button" onClick={handleSearchIconClick} style={{ backgroundColor: '#5075d0', color: 'white' }}>
                            <i className="fa-solid fa-magnifying-glass"></i>
                        </button>
                    </div>
                </div>
                <div className="row my-2 g-3 g-lg-4 contact-section">
                    <div className="col">
                        <div className="tab-content" id="pills-tabContent">
                            <div className="tab-pane fade show active" id="pills-home" role="tabpanel" aria-labelledby="pills-home-tab" tabIndex="0">
                                <div className="row my-2 g-3 g-lg-3">
                                    <div className="col">
                                        <div className="product-table p-3 px-md-4">
                                            <div className="table-responsive">
                                                <table className="table align-middle mb-0 bg-white">
                                                    <thead className="bg-light">
                                                        <tr>
                                                            <th style={{ cursor: 'pointer' }} onClick={() => getCustomers()}>#</th>
                                                            <th style={{ cursor: 'pointer' }} onClick={() => handleSort('first_name')}>Name</th>
                                                            <th style={{ cursor: 'pointer' }} onClick={() => handleSort('email')}>Email</th>
                                                            <th style={{ cursor: 'pointer' }} onClick={() => handleSort('created_at')}>Registration Date</th>
                                                            <th>Companies</th>
                                                            <th>Actions</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {customers && customers.length > 0 ? (
                                                            customers.map((customer, index) => (
                                                                <tr key={index}>
                                                                    <td>{index + 1}</td>
                                                                    <td>{customer.first_name} {customer.last_name}</td>
                                                                    <td>{customer.email}</td>
                                                                    <td>{new Date(customer.signup_date).toLocaleDateString()}</td>
                                                                    <td>{customer.companies && customer.companies.length > 0 ? `${customer.companies[0].name} and ${customer.companies.length - 1} more` : 'No companies'}</td>
                                                                    <td>
                                                                        <Link to={`/admin/customers/${customer.id}`}>
                                                                            <button type="button" className="btn btn-link btn-sm rounded-pill">
                                                                                View Details
                                                                            </button>
                                                                        </Link>
                                                                    </td>
                                                                </tr>
                                                            ))
                                                        ) : (
                                                            <tr>
                                                                <td colSpan="5" className="text-center">No Customers Found</td>
                                                            </tr>
                                                        )}
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default WithAuth(AdminCustomers);
