import React, { useEffect, useContext, useState } from "react";
import { Context } from "../../store/appContext";
import { Navigate } from "react-router-dom";

const WithAuth = (Component) => {
    const AuthRoute = () => {
        const { store, actions } = useContext(Context);
        const [isCheckingAuth, setIsCheckingAuth] = useState(true);

        useEffect(() => {
            const checkAuth = async () => {
                let token = localStorage.getItem("token") || sessionStorage.getItem("token");

                if (!token) {
                    setIsCheckingAuth(false);
                    return;
                }

                // Call the /validate-token API
                let { response } = await actions.useFetch("/validate-token", {}, "POST");

                if (response.ok) {
                    if (!store.userLogin) {
                        try {
                            await actions.getUserData();
                            await actions.getUserDocs();
                            actions.updateUserLogin(true); // Restore userLogin after refresh
                        } catch (error) {
                            console.log("Error fetching user data:", error);
                        }
                    }
                } else {
                    console.log("Token expired or invalid");
                    localStorage.removeItem("token");
                    sessionStorage.removeItem("token");
                    actions.updateUserLogin(false);
                }

                setIsCheckingAuth(false);
            };

            checkAuth();
        }, []);

        if (isCheckingAuth) return <div>Loading...</div>;
        return store.userLogin ? <Component /> : <Navigate to="/login" />;
    };

    return AuthRoute;
};

export default WithAuth;
