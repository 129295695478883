import React, { useContext, useState, useEffect } from "react";
import { Context } from "../store/appContext";
import "../../styles/home.css";
import SearchDomains from "../component/searchDomains.jsx";
import WithAuth from "../component/Auth/withAuth";

export const BusinessDomain = () => {
    const { store, actions } = useContext(Context);
    const [selectedCompany, setSelectedCompany] = useState("");
    const [myCompanies, setMyCompanies] = useState(store.userData.companies)
    const [companyDomain, setCompanyDomain] = useState([])

    const getCompanyDomain = async (companyId) => {
        let { responseJson, response } = await actions.useFetch(`/domains/${companyId}`)
        if (!response.ok) {
            console.log("Failed getting company domain:", responseJson)
            setCompanyDomain(responseJson)
        } else {
            setCompanyDomain(responseJson)
        }
    }

    useEffect(() => {
        if (selectedCompany) {
            getCompanyDomain(selectedCompany);
        }
    }, [selectedCompany]);

    return (
        <>
            <div className="container-fluid main-content px-2 px-lg-4 pt-3 pt-lg-5 mt-5">

                <div className="row">
                    <h3 className="mt-4">Website Domains</h3>
                    <span className="medium">Here you will find the information about your business domain</span>
                </div>
                <div className="col-lg-4 mt-3">
                    <select id="document-type-select" className="form-select" aria-label="Default select example" onChange={(e) => setSelectedCompany(e.target.value)}>
                        <option value="">Select your company to view its domains...</option>
                        {myCompanies && myCompanies.length > 0 && (
                            myCompanies.map((item, index) => (
                                <option key={index} value={item.id}>{item.name}</option>
                            ))
                        )}
                    </select>
                </div>
                <div className="row g-3 g-lg-4 pb-4 mt-1">
                    {companyDomain && companyDomain["error"] ? (
                        <>
                            < SearchDomains selectedCompany={selectedCompany} getCompanyDomain={getCompanyDomain} />
                        </>
                    ) : (
                        <>
                            <h6>Your Business Domain Is: {companyDomain.domain_name} </h6>

                        </>
                    )
                    }
                </div>
            </div>
        </>
    );
};

export default WithAuth(BusinessDomain);