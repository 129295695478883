import React from "react";
import '../../styles/services-details.css';
// import Network from "../../img/network.jpg";
import WithAuth from "../component/Auth/withAuth";

function userNetwork() {
    return (

        <div className="service-page-container">
            <div className="service-header">
                <h1>Networking and Community Access</h1>
            </div>
            <div className="service-content">
                <img src={Network} alt="Legal Documents" className="service-image" />
                <div className="service-description">
                    <p>
                        At the heart of every successful business is a solid network of industry relationships and community ties. Afthonia's Networking and Community Access services are crafted to open doors for your LLC, connecting you with a wealth of opportunities and resources.</p>
                    <p>
                        <strong>Discover the benefits of our Networking and Community Access:</strong>
                        <br />
                        <br /><strong>1.-Strategic Industry Connections:</strong>We link your business with key industry players, from potential partners to seasoned mentors, enhancing your market presence and business potential.

                        <br /><strong>2.-Exclusive Community Events:</strong>Gain entry to exclusive events, workshops, and seminars that not only provide networking opportunities but also offer insights into the latest industry trends and business strategies.

                        <br /><strong>3.-Online Networking Platforms:</strong>Leverage our online forums and networking platforms to connect with peers, exchange ideas, and find collaboration opportunities in a dynamic digital environment.

                        <br /><strong>4.-Business Development Programs:</strong>Participate in programs designed to foster business growth and development, including incubators, accelerators, and pitch events that can take your business to the next level.

                        <br /><strong>5.-Resource Sharing:</strong>ap into a shared pool of resources facilitated by our extensive network, from office spaces and tech tools to specialized services, optimizing your operational costs.

                        <br /><strong>6.-Local Community Engagement:</strong>We help you build a strong local presence by connecting you with community leaders and initiatives, aligning your business with community values and earning local support.

                        Afthonia’s Networking and Community Access services are not just about making connections; they're about building lasting relationships that nurture your business's growth. We are committed to cultivating a vibrant ecosystem where your LLC can thrive, driven by collaborative success and mutual progress.

                    </p>
                </div>
            </div>
            <div className="call-to-action">
                <button className="btn btn-transparent" style={{ background: '#8da9ee', color: 'white' }}>Get Started</button>
            </div>
        </div>
    );
};

export default WithAuth(userNetwork);