import React from "react";
import '../../styles/services-details.css';
// import Customer from "../../img/customer.jpg";
import WithAuth from "../component/Auth/withAuth";

function userCustomer() {
    return (

        <div className="service-page-container">
            <div className="service-header">
                <h1>Customer Support Management</h1>
            </div>
            <div className="service-content">
                <img src={Customer} alt="Legal Documents" className="service-image" />
                <div className="service-description">
                    <p>
                        In today's competitive market, exceptional customer support is not just a service; it's an integral part of a successful business. Afthonia's Customer Support Management services are designed to enhance your LLC's customer relations and ensure customer satisfaction, retention, and loyalty.</p>

                    <p>
                        <strong>Our Customer Support Management services include:</strong>
                        <br />
                        <br /><strong>1.-Personalized Support Strategies:</strong>We create tailored support strategies that resonate with your brand's voice and values, ensuring your customers' expectations are not just met but exceeded.

                        <br /><strong>2.-Efficient Issue Resolution:</strong>Our team implements streamlined processes for quick and effective resolution of customer inquiries and issues, fostering trust and reliability in your customer base.

                        <br /><strong>3.-Multichannel Support Systems:</strong>We integrate support across various channels, including email, phone, live chat, and social media, providing your customers with the convenience to reach out through their preferred medium.

                        <br /><strong>4.-Customer Feedback and Insights:</strong>By systematically gathering and analyzing customer feedback, we help you understand your clientele better and make informed decisions to enhance your products and services.
                        <br /><strong>5.-Training and Development:</strong>We equip your support team with the necessary skills and knowledge through comprehensive training programs, ensuring they are prepared to handle every customer interaction with professionalism and empathy.
                        <br /><strong>6.-Scalable Support Solutions:</strong>Whether you're experiencing rapid growth or seasonal spikes, our customer support services scale to meet the changing needs of your business, ensuring quality is never compromised.
                        <br /><strong>7.-Proactive Engagement:</strong> We don't just wait for customers to reach out; our proactive engagement strategies involve reaching out to customers with updates, helpful tips, and personalized offers to keep them engaged and invested in your brand.

                        With Afthonia's Customer Support Management, your LLC will not only retain a loyal customer base but also turn satisfied customers into vocal advocates for your brand. Our mission is to ensure that every customer feels heard, valued, and supported at every touchpoint with your business.
                    </p>
                </div>
            </div>
            <div className="call-to-action">
                <button className="btn btn-transparent" style={{ background: '#8da9ee', color: 'white' }}>Get Started</button>
            </div>
        </div>
    );
};

export default WithAuth(userCustomer);