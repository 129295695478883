import React, { useContext, useState, useEffect } from "react";
import { Link, useParams, useNavigate } from "react-router-dom";
import { Context } from "../store/appContext";
import "../../styles/companies-details.css";
import Swal from 'sweetalert2'
import WithAuth from "../component/Auth/withAuth";

export const AdminNewService = () => {
    const { store, actions } = useContext(Context);
    const params = useParams();
    const navigate = useNavigate()
    let [serviceInfo, setServiceInfo] = useState({
        service: '',
        price: '',
        description: '',
        frequency: ''
    });

    const handleInputChange = (e) => {
        const { name, value } = e.target;

        if (name === "price") {
            // Remove any non-numeric characters
            let numericValue = value.replace(/\D/g, ""); // Only numbers

            if (numericValue === "") {
                setServiceInfo((prevState) => ({ ...prevState, [name]: "" }));
                return;
            }

            // Convert numeric input to dollars and cents
            let centsValue = parseInt(numericValue, 10) / 100;

            // Format with commas and two decimal places
            let formattedValue = centsValue.toLocaleString("en-US", {
                style: "currency",
                currency: "USD",
                minimumFractionDigits: 2,
            });

            setServiceInfo((prevState) => ({ ...prevState, [name]: formattedValue }));
        } else {
            setServiceInfo((prevState) => ({ ...prevState, [name]: value }));
        }
    };

    const newService = async () => {

        let { responseJson, response } = await actions.useFetch("/services", serviceInfo, "POST")
        if (response.ok) {
            Swal.fire({
                title: 'Great!',
                text: 'You have successfully updated the information',
                icon: 'success',
                confirmButtonColor: '#4874d7'
            })
            navigate("/admin/services")
        } else {
            Swal.fire({
                title: 'Sorry!',
                text: 'There was an error, please try again',
                icon: 'error',
                confirmButtonColor: '#4874d7'
            })
        }
    }


    return (
        <>
            <div className="container-fluid main-content px-2 px-lg-4 pt-3 pt-lg-5 mt-5">

                <div className="row">
                    <h3 className="mt-4">Add New Service</h3>
                    <span className="medium">Here you can edit add the service information.</span>
                </div>
                <div className="row g-3 g-lg-4 profile-details-content-3 pb-4" style={{ marginTop: "0px" }}>
                    <div className="col-xl">
                        <div className="p-2 p-sm-4 right-side rounded">
                            <h4 className="mb-3">Service Details</h4>
                            <div className="d-flex row">
                                <div className="col-lg">
                                    <label htmlFor="service" className="large mb-2">Service Name</label>
                                    <input
                                        type="text"
                                        name="service"
                                        id="service"
                                        placeholder="Service Name"
                                        className="form-control mb-3 py-2"
                                        value={serviceInfo.service}
                                        onChange={handleInputChange}
                                    />
                                </div>
                                <div className="col-lg-4">
                                    <label htmlFor="price" className="large mb-2">Price</label>
                                    <input
                                        type="text"
                                        name="price"
                                        id="price"
                                        placeholder="Price"
                                        className="form-control mb-3 py-2"
                                        value={serviceInfo.price}
                                        onChange={handleInputChange}
                                    />
                                </div>
                                <div className="col-lg-2">
                                    <label htmlFor="frequency" className="large mb-2">Frequency</label>
                                    <select
                                        name="frequency"
                                        id="frequency"
                                        className="form-select"
                                        value={serviceInfo.frequency}
                                        onChange={handleInputChange}
                                    >
                                        <option value="">N/A</option>
                                        <option value="day">Daily</option>
                                        <option value="week">Weekly</option>
                                        <option value="month">Monthly</option>
                                        <option value="year">Yearly</option>
                                    </select>
                                </div>
                            </div>
                            <div className="d-flex row">
                                <div className="col-lg">
                                    <label htmlFor="description" className="large mb-2">Description</label>
                                    <input
                                        type="text"
                                        name="description"
                                        id="description"
                                        placeholder="Description"
                                        className="form-control mb-3 py-2"
                                        value={serviceInfo.description}
                                        onChange={handleInputChange}
                                    />
                                </div>
                            </div>
                            <div className="d-flex justify-content-center">
                                <button className="btn btn-primary m-1" onClick={newService}>
                                    Save
                                </button>
                                <Link to="/admin/services">
                                    <button className="btn btn-danger m-1">
                                        Cancel
                                    </button>
                                </Link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );

};

export default WithAuth(AdminNewService);
