import React from "react";
import '../../styles/services-details.css';
// import Trademark from "../../img/trademark.jpg";
import WithAuth from "../component/Auth/withAuth";

function userTrademark() {
    return (

        <div className="service-page-container">
            <div className="service-header">
                <h1>Trademark and Intellectual Property</h1>
            </div>
            <div className="service-content">
                <img src={Trademark} alt="Legal Documents" className="service-image" />
                <div className="service-description">
                    <p>
                        In the entrepreneurial journey, protecting your intellectual property is as crucial as the innovation itself. Afthonia's Trademark and Intellectual Property Services provide your LLC with the robust protection it needs in the complex landscape of intellectual rights.</p>
                    <p>
                        <strong>Empower your business with our comprehensive services:</strong>
                        <br />
                        <br /><strong>1.-Trademark Registration:</strong>Safeguard your brand identity with our trademark registration service that navigates the legalities to secure your logos, slogans, and brand names, ensuring they remain exclusively yours.

                        <br /><strong>2.-Intellectual Property Strategy:</strong>Our experts help you devise a strategic plan to manage and leverage your intellectual property, maximizing its value and ensuring a competitive edge in your industry.

                        <br /><strong>3.-IP Portfolio Management:</strong>We offer meticulous management of your IP portfolio, ensuring that all your valuable assets are accounted for, protected, and maintained over time.

                        <br /><strong>4.-Infringement Monitoring:</strong>Stay vigilant against potential infringements with our monitoring services, designed to detect and address unauthorized use of your intellectual property swiftly.
                        <br /><strong>5.-Enforcement and Litigation Support:</strong>In the event of infringement, our team stands ready to enforce your rights, providing the necessary support through legal channels to resolve disputes in your favor.
                        <br /><strong>6.-IP Education and Training:</strong>Knowledge is power. We provide educational resources and training to your team on IP best practices, helping prevent inadvertent violations and fostering a culture of intellectual property respect within your company.

                        By entrusting Afthonia with your intellectual assets, you ensure that your innovations and creative efforts are protected, allowing you to focus on growth and development with peace of mind. Our goal is to transform your intellectual property into tangible assets that propel your business forward.
                    </p>
                </div>
            </div>
            <div className="call-to-action">
                <button className="btn btn-transparent" style={{ background: '#8da9ee', color: 'white' }}>Get Started</button>
            </div>
        </div>
    );
};

export default WithAuth(userTrademark);