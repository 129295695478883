import React, { useContext, useState, useEffect } from "react";
import { Context } from "../store/appContext";
import "../../styles/home.css";
import docsImage from "../../images/docs.jpeg"
import WithAuth from "../component/Auth/withAuth";

export const UserInvoices = () => {
    const { store, actions } = useContext(Context);
    const [selectedCompany, setSelectedCompany] = useState("");
    const [myCompanies, setMyCompanies] = useState(store.userData.companies)
    const [companyInvoices, setCompanyInvoices] = useState([])
    const [loadingIds, setLoadingIds] = useState({});

    const getCompanyDocs = async (companyId) => {
        let { responseJson, response } = await actions.useFetch(`/invoices/${companyId}`)
        if (response.ok) {
            setCompanyInvoices(responseJson)
        } else {
            console.log("Failed getting company invoices")
        }
    }

    const openPDF = async (filePath, id) => {
        setLoadingIds(prevState => ({ ...prevState, [id]: true }));
        const body = { filepath: filePath };

        try {
            await actions.downloadUserDocument("/download", body, "POST");
        } catch (error) {
            console.error(error);
        } finally {
            setLoadingIds(prevState => ({ ...prevState, [id]: false }));
        }
    };

    useEffect(() => {
        setMyCompanies(store.userData.companies)
    }, [store.userData.companies]);

    useEffect(() => {
        if (selectedCompany) {
            getCompanyDocs(selectedCompany);
        }
    }, [selectedCompany]);

    return (
        <>
            <div className="container-fluid main-content px-2 px-lg-4 pt-3 pt-lg-5 mt-5">

                <div className="row">
                    <h3 className="mt-4">Invoices</h3>
                    <span className="medium">Here you will find all your company's invoices</span>
                </div>
                <div className="col-lg-4 mt-3">
                    <select id="document-type-select" className="form-select" aria-label="Default select example" onChange={(e) => setSelectedCompany(e.target.value)}>
                        <option value="">Select your company to view its invoices...</option>
                        {myCompanies && myCompanies.length > 0 && (
                            myCompanies.map((item, index) => (
                                <option key={index} value={item.id}>{item.name}</option>
                            ))
                        )}
                    </select>
                </div>
                <div className="row my-2 g-3 g-lg-4 contact-section">
                    <div className="col">
                        <div className="tab-content" id="pills-tabContent">
                            <div
                                className="tab-pane fade show active"
                                id="pills-home"
                                role="tabpanel"
                                aria-labelledby="pills-home-tab"
                                tabIndex="0"
                            >
                                <div className="row my-2 g-3 g-lg-3">
                                    <div className="col">
                                        <div className="product-table p-3 px-md-4">
                                            <div className="table-responsive">
                                                <table className="table align-middle mb-0 bg-white">
                                                    <thead className="bg-light">
                                                        <tr>
                                                            <th>Invoice No.</th>
                                                            <th>Date</th>
                                                            <th>Payment Status</th>
                                                            <th>Actions</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {companyInvoices && companyInvoices.length > 0 ? (
                                                            companyInvoices.map((item, index) => (
                                                                <tr key={index}>
                                                                    <td>
                                                                        <p className="fw-normal mb-1">{item.id}</p>
                                                                    </td>
                                                                    <td>
                                                                        <p className="fw-normal mb-1">{item.upload_date}</p>
                                                                    </td>
                                                                    <td>
                                                                        <p className="fw-normal mb-1">Status</p>
                                                                    </td>
                                                                    <td>
                                                                        <button
                                                                            type="button"
                                                                            className="btn btn-link btn-sm rounded-pill"
                                                                            onClick={() => openPDF(item.file_path, item.id)}
                                                                            disabled={loadingIds[item.id]}
                                                                        >
                                                                            {loadingIds[item.id] ? (
                                                                                <div className="spinner-border" role="status">
                                                                                    <span className="visually-hidden">Loading...</span>
                                                                                </div>
                                                                            ) : (
                                                                                "View Invoice"
                                                                            )}
                                                                        </button>
                                                                    </td>
                                                                </tr>
                                                            ))
                                                        ) : (
                                                            <tr>
                                                                <td colSpan="5" className="text-center">No Invoices to Show</td>
                                                            </tr>
                                                        )}

                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default WithAuth(UserInvoices);