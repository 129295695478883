import React, { useContext, useState, useEffect } from "react";
import { Context } from "../store/appContext";
import "../../styles/home.css";
import Swal from 'sweetalert2'
import docsImage from "../../images/docs.jpeg"
import WithAuth from "../component/Auth/withAuth";

export const EditProfile = () => {
    const { store, actions } = useContext(Context);
    const [statesList, setStatesList] = useState(store.states)
    const [countryList, setCountryList] = useState(store.countries)
    const [userInfo, setUserInfo] = useState(store.userData)
    const [currentPassword, setCurrentPassword] = useState("")
    const [newPassword, setNewPassword] = useState("")
    const [readOnlyPersonal, setReadOnlyPersonal] = useState(true)
    const [readOnlyPassword, setReadOnlyPassword] = useState(true)
    const [readOnlyAddress, setReadOnlyAddress] = useState(true)
    const isUSCountrySelected = userInfo.country === 'US';

    const handleInputChange = e => {
        const { name, value } = e.target;
        setUserInfo(prevState => ({ ...prevState, [name]: value }));
    };

    const updatePersonal = async () => {
        const { companies, ...userInfoWithoutCompanies } = userInfo;
        let { responseJson, response } = await actions.useFetch(`/users/${userInfo.id}`, userInfoWithoutCompanies, "PUT")
        if (response.ok) {
            await actions.getUserData()
            setReadOnlyPersonal(!readOnlyPersonal)
            Swal.fire({
                title: 'Great!',
                text: 'You have successfully updated the information',
                icon: 'success',
                confirmButtonColor: '#4874d7'
            })
        } else {
            Swal.fire({
                title: 'Sorry!',
                text: 'There was an error, please try again',
                icon: 'error',
                confirmButtonColor: '#4874d7'
            })
        }
    }

    const updateAddress = async () => {
        const { companies, ...userInfoWithoutCompanies } = userInfo;
        let { responseJson, response } = await actions.useFetch(`/users/${userInfo.id}`, userInfoWithoutCompanies, "PUT")
        if (response.ok) {
            await actions.getUserData()
            setReadOnlyAddress(!readOnlyAddress)
            Swal.fire({
                title: 'Great!',
                text: 'You have successfully updated the information',
                icon: 'success',
                confirmButtonColor: '#4874d7'
            })
        } else {
            Swal.fire({
                title: 'Sorry!',
                text: 'There was an error, please try again',
                icon: 'error',
                confirmButtonColor: '#4874d7'
            })
        }
    }

    const updatePassword = async () => {
        let body = {
            current_password: currentPassword,
            new_password: newPassword
        }
        let { responseJson, response } = await actions.useFetch(`/change_password/${userInfo.id}`, body, "PUT")
        if (response.ok) {
            setReadOnlyPassword(!readOnlyPassword)
            Swal.fire({
                title: 'Great!',
                text: 'You have successfully changed your password',
                icon: 'success',
                confirmButtonColor: '#4874d7'
            })
        } else {
            Swal.fire({
                title: 'Sorry!',
                text: 'There was an error, please try again',
                icon: 'error',
                confirmButtonColor: '#4874d7'
            })
        }
    }

    useEffect(() => {
        setUserInfo(store.userData)
    }, [store.userData]);


    return (
        <>
            <div className="container-fluid main-content px-2 px-lg-4 pt-3 pt-lg-5 mt-5">

                <div className="row">
                    <h3 className="mt-4">Edit Profile</h3>
                    <span className="medium">Here you can update your profile and personal information.</span>
                </div>
                <div className="row g-3 g-lg-4 profile-details-content-3 pb-4" style={{ marginTop: "0px" }}>
                    <div className="col-xl-6">
                        <div className="p-2 p-sm-4 right-side rounded">
                            <h4 className="mb-3">Profile Details</h4>
                            <div className="d-flex row">
                                <div className="col-lg-6">
                                    <label htmlFor="name" className="large mb-2">First Name</label>
                                    <input
                                        type="text"
                                        name="first_name"
                                        id="name"
                                        placeholder="First Name"
                                        className="form-control mb-3 py-2"
                                        value={userInfo.first_name}
                                        onChange={handleInputChange}
                                        readOnly={readOnlyPersonal}
                                    />
                                </div>
                                <div className="col-lg-6">
                                    <label htmlFor="last-name" className="large mb-2">Last Name</label>
                                    <input
                                        type="text"
                                        name="last_name"
                                        id="last-name"
                                        placeholder="Last Name"
                                        className="form-control mb-3 py-2"
                                        value={userInfo.last_name}
                                        onChange={handleInputChange}
                                        readOnly={readOnlyPersonal}
                                    />
                                </div>
                            </div>
                            <div className="d-flex row">
                                <div className="col-lg-6">
                                    <label htmlFor="email" className="large mb-2">Email Address</label>
                                    <input
                                        type="email"
                                        name="email"
                                        id="email"
                                        placeholder="info@example.com"
                                        className="form-control mb-3 py-2"
                                        value={userInfo.email}
                                        onChange={handleInputChange}
                                        readOnly={readOnlyPersonal}
                                    />
                                </div>
                                <div className="col-lg-6">
                                    <label htmlFor="phone" className="large mb-2">Phone Number</label>
                                    <input
                                        type="text"
                                        name="phone"
                                        id="phone"
                                        placeholder="123 456 7890"
                                        className="form-control mb-3 py-2"
                                        value={userInfo.phone}
                                        onChange={handleInputChange}
                                        readOnly={readOnlyPersonal}
                                    />
                                </div>
                            </div>
                            <div className="d-flex justify-content-center">
                                {readOnlyPersonal ?
                                    <button className="btn btn-primary" onClick={() => setReadOnlyPersonal(!readOnlyPersonal)}>
                                        Edit
                                    </button>
                                    :
                                    <>
                                        <button className="btn btn-primary m-1" onClick={updatePersonal}>
                                            Save
                                        </button>
                                        <button className="btn btn-danger m-1" onClick={() => setReadOnlyPersonal(!readOnlyPersonal)}>
                                            Cancel
                                        </button>
                                    </>}

                            </div>
                        </div>
                    </div>
                    <div className="col-xl-6">
                        <div className="company-details px-3 px-lg-4 pb-4 pt-4 rounded">
                            <h4 className="mb-3">Change Password</h4>
                            <div className="d-flex row">
                                <div className="col-lg-6">
                                    <label htmlFor="txtPassword" className="large mb-2">Current Password</label>
                                    <input
                                        type="password"
                                        name="password"
                                        id="txtPassword"
                                        placeholder="Current Password"
                                        className="form-control mb-3 py-2"
                                        value={currentPassword}
                                        onChange={(e) => setCurrentPassword(e.target.value)}
                                        readOnly={readOnlyPassword}
                                    />
                                </div>
                                <div className="col-lg-6">
                                    <label htmlFor="txtPassword2" className="large mb-2"
                                    >New Password</label
                                    >
                                    <input
                                        type="password"
                                        name="confirmPassword"
                                        id="txtPassword2"
                                        placeholder="New Password"
                                        className="form-control mb-3 py-2"
                                        value={newPassword}
                                        onChange={(e) => setNewPassword(e.target.value)}
                                        readOnly={readOnlyPassword}
                                    />
                                </div>
                                <div className="d-flex justify-content-center">
                                    {readOnlyPassword ?
                                        <button className="btn btn-primary" onClick={() => setReadOnlyPassword(!readOnlyPassword)}>
                                            Change Password
                                        </button>
                                        :
                                        <>
                                            <button className="btn btn-primary m-1" onClick={updatePassword}>
                                                Save
                                            </button>
                                            <button className="btn btn-danger m-1" onClick={() => setReadOnlyPassword(!readOnlyPassword)}>
                                                Cancel
                                            </button>
                                        </>}

                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-xl-6">
                        <div className="company-details px-3 px-lg-4 pb-4 pt-4 rounded">
                            <h4 className="mb-3">Address</h4>
                            <div className="row">
                                <div className="col-lg-6">
                                    <label htmlFor="inputAddress" className="large mb-2">Address</label>
                                    <input type="text" className="form-control mb-3 py-2" name="address" id="inputAddress" placeholder="1234 Main St" value={userInfo.address} onChange={handleInputChange} readOnly={readOnlyAddress} />
                                </div>
                                <div className="col-lg-6">
                                    <label htmlFor="inputAddress2" className="large mb-2">Address 2</label>
                                    <input type="text" className="form-control mb-3 py-2" name="address_2" id="inputAddress2" placeholder="Apartment, studio, or floor" value={userInfo.address_2} onChange={handleInputChange} readOnly={readOnlyAddress} />
                                </div>
                                <div className="col-lg-6">
                                    <label htmlFor="inputCity" className="large mb-2">City</label>
                                    <input type="text" className="form-control mb-3 py-2" name="city" id="inputCity" value={userInfo.city} onChange={handleInputChange} readOnly={readOnlyAddress} />
                                </div>
                                <div className="col-lg-6">
                                    <label htmlFor="inputState" className="large mb-2">State</label>
                                    {isUSCountrySelected ? (
                                        <select name="state" id="inputState" className="form-select mb-3 py-2" value={userInfo.state} onChange={handleInputChange} disabled={readOnlyAddress}>
                                            <option>Choose...</option>
                                            {statesList.map((item, index) => (
                                                <option key={index} value={item.code}>
                                                    {item.state}
                                                </option>
                                            ))}
                                        </select>
                                    ) : (
                                        <input
                                            type="text"
                                            name="state"
                                            id="inputState"
                                            className="form-control mb-3 py-2"
                                            value={userInfo.state}
                                            onChange={handleInputChange}
                                            disabled={readOnlyAddress}
                                        />
                                    )}
                                </div>
                                <div className="col-lg-8">
                                    <label htmlFor="inputCountry" className="large mb-2">Country</label>
                                    <select name="country" id="inputCountry" className="form-select mb-3 py-2" value={userInfo.country} onChange={handleInputChange} disabled={readOnlyAddress}>
                                        <option>Choose...</option>
                                        {countryList.map((item, index) => (
                                            <option key={index} value={item.code}>
                                                {item.country}
                                            </option>
                                        ))}
                                    </select>
                                </div>
                                <div className="col-lg-4">
                                    <label htmlFor="inputZip" className="large mb-2">Zip</label>
                                    <input type="text" className="form-control mb-3 py-2" name="zip" id="inputZip" value={userInfo.zip} onChange={handleInputChange} readOnly={readOnlyAddress} />
                                </div>
                                <div className="d-flex justify-content-center">
                                    {readOnlyAddress ?
                                        <button className="btn btn-primary" onClick={() => setReadOnlyAddress(!readOnlyAddress)}>
                                            Edit
                                        </button>
                                        :
                                        <>
                                            <button className="btn btn-primary m-1" onClick={updateAddress}>
                                                Save
                                            </button>
                                            <button className="btn btn-danger m-1" onClick={() => setReadOnlyAddress(!readOnlyAddress)}>
                                                Cancel
                                            </button>
                                        </>}

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default WithAuth(EditProfile);